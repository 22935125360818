
import req_detail_datas from "@/lib/data-service/haolv-default/consumer_journey_getApplyRefund";


export default {
  components: {
    
  },
  data() {
    return {
			loading: false,
      id: '',
			detail: {
          // 申请单详情
          approveApply: null,
          // 出差单信息
          evection: null,
          // 行程集合
          journeyList: null,
          // 流程集合
          recordList: null,
          // 机票信息
          filght: null,
          // 酒店信息
          hotel: null,
          // 火车票信息
          train: null,
          // 出行人
          staffDetailVos: null,
          // 操作业务类型
          type: null,
      },
    };
  },
  computed: {},
  methods: {
    req_detail (id) {
      if (this.loading) return;
      this.loading = true;
      this.detail = {};
			req_detail_datas({id}).then((res) => {
        const detail = res.datas;
        this.detail = {
          // 申请单详情
          approveApply: detail.approveApply,
          // 出差单信息
          evection: detail.evection,
          // 行程集合
          journeyList: detail.journeyList,
          // 流程集合
          recordList: detail.recordList,
          // 机票信息
          filght: detail.refundOrdersDetailRespone,
          hotel: detail.todHotelOrderInterimResponse,
          train: detail.trainsOrderReturnDetailResponse,
          // 出行人
          staffDetailVos: detail.staffDetailVos,
          // 操作业务类型
          type: detail.type,
        };
        this.loading = false;
			}).catch((err) => {
        this.loading = false;
			})
		},
		init () {
			this.id = this.$route.query.id;
			this.req_detail(this.id);
		}
  },
  created() {},
  mounted() {
    
  },
  activated() {
    this.init();
  },
  deactivated() {},
  destroyed() {},
  watch: {},
  filters: {
    
  },
};
